var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"collection-card card-display pa-4"},[_c('div',{staticClass:"collection-header"},[_c('div',{staticClass:"title-container"},[_c('v-card-title',{staticClass:"pl-3 pr-2 py-3"},[_vm._v(" "+_vm._s(_vm.localCollectionName)+" ")]),_c('div',{staticClass:"product-count-badge"},[_vm._v(" "+_vm._s(_vm.productTotal)+" ")])],1),_c('div',{staticClass:"header-actions px-2"},[_c('v-btn',{staticClass:"desktop",attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.$emit('updateSelectedCollectionId', _vm.collection.vtexCollectionId)}}},[_c('v-icon',{attrs:{"color":"iconGrey"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mobile",attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push({ path: ("collection/" + (_vm.collection.vtexCollectionId)) })}}},[_c('v-icon',{attrs:{"color":"iconGrey"}},[_vm._v("mdi-pencil")])],1),_c('Dialog',{attrs:{"title":"Delete Collection","text":("Are you sure you would like to delete " + _vm.localCollectionName + "? This is permanent and cannot be undone."),"okText":"Delete","okTextColor":"red","cancelText":"Cancel"},on:{"ok":function($event){return _vm.deleteCollection(_vm.collection.vtexCollectionId)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","text":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"iconGrey"}},[_vm._v("mdi-delete")])],1)]}}])})],1)]),_c('v-data-table',{staticClass:"custom-table desktop mt-4",attrs:{"loading":_vm.loading,"loading-text":_vm.loading ? 'Loading Collection Products...' : '',"height":"100%","id":"collection-products-table","headers":_vm.headers,"fixed-header":"","items":_vm.collectionProducts,"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":-1,"disable-sort":""},scopedSlots:_vm._u([{key:"item.vtexCoverUrl",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"product-image",attrs:{"src":item.vtexCoverUrl,"cover":"","alt":item.vtexProductName + ' cover'}})]}},{key:"item.pepperNumber",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://www.jwpepper.com/" + (item.pepperNumber) + ".item"),"target":"_blank"}},[_vm._v(_vm._s(item.pepperNumber))])]}}],null,true)}),(!_vm.loading && _vm.collectionProducts.length)?_c('CollectionProducts',{staticClass:"cards mobile mt-2",attrs:{"products":_vm.collectionProducts}}):(_vm.loading)?_c('v-card',{staticClass:"mt-4 loading-items mobile",attrs:{"elevation":"0"}},[_c('div',{staticClass:"loading-content"},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":80,"width":10,"color":"lightGold"}})],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }