import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#133E4F", // My Score Blue
        secondary: "#1e1e1e", // JWP dark
        accent: "#EB8F2C", // JWP Orange
        error: "#9d1e1e",
        info: "#34638A",
        success: "#EB8F2C", // My Score "Call to Action" Orange
        warning: "#EB8F2C",
        lightBlue: "#34638A",
        darkGold: "#3D2510",
        lightGold: "#EB8F2C",
        iconGrey: "#707070",
      },
    },
  },
});
