import { render, staticRenderFns } from "./CatalogPage.vue?vue&type=template&id=3fc40f0b&scoped=true&"
import script from "./CatalogPage.vue?vue&type=script&lang=ts&"
export * from "./CatalogPage.vue?vue&type=script&lang=ts&"
import style0 from "./CatalogPage.vue?vue&type=style&index=0&id=3fc40f0b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc40f0b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VContainer,VProgressCircular})
